import * as React from "react";
import { Component } from 'react';
import Layout from '../../components/global/layout.js'
import Content from '../../components/championsofchange/subpages/Linda.js'
import '@fortawesome/fontawesome-free/css/all.css';
import '../../sass/app.scss';

class Linda extends Component {
  render() {
    return (
      <>
        <Layout section="community-involvement" page="champion-linda">
          <Content section="community-involvement" page="champion-linda" />
        </Layout>
      </>
    )
  }
}

export default Linda;
